.container-pulse{
  position:absolute;
  right:10%;
  bottom:15%;

}

.pulse{
position: relative;
width: 15px;
height: 15px;
background-color: none;
border-radius: 50%;
text-align: center;
line-height: 15px;
font-size: 18px;
}
.pulse:after,
.pulse:before {
  content: '';
  position: absolute;
  left: -10px;
  top: -8px;
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
  border: 1px solid white;
  animation: pulse 1.5s linear infinite;
}
.pulse:after {
  animation-delay: 0.4s;
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.teste{
  margin-right:0.3rem;
}



