.fc .fc-col-header-cell-cushion {
  padding-top: 15px;
  padding-bottom: 15px;
}

#fc-dom-1 {
  color: #525f7f;
  font-weight: 600;
  font-family: inherit;
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 1rem 0 0 1rem;
}
.fc-event-title-container {
  border: transparent;
  text-align: center;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(255, 220, 40, 0.15);
  background-color: rgba(255, 220, 40, 0.15);
  background-color: rgb(0, 0, 0, 0.25);
}
.fc-daygrid-event-harness {
  margin: 0 0.5rem;
  height: 2rem;
}
.bg-yellow,.bg-green,.bg-blue {
  border: none;
  font-weight: bold;


  padding: 2px;
}



