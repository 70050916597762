.attachment-name-container:hover {
  background-color: #f5f5f5;
}

.delete-icon {
  display: none;
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
}

.attachment-name-container:hover .delete-icon {
  display: inline-block;
}

