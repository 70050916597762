.login-container {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  height: 100vh;
}

.login-container .logo {
  text-align: center;
  flex-basis: 60%;
}

.login-container .logo img {
  width: 80%;
  max-width: 900px;
}

.login-container .form-container {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  margin: 50px;
  max-width: 550px;
}

.login-container .form-container .img-logo-g4flex {
  text-align: center;
  margin-bottom: 25px;
}

.login-container .form-container .form {
  /* flex-basis: 40%; */
  padding: 50px;
  /* margin: 50px; */
}

/* .g4-button {
    border: 1px solid #9ab744;
    background-color: #9ab744;
    color: white;
  } */

/* .g4-button:hover {
    border: 1px solid grey;
    background-color: grey;
    color: white;
  } */

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 2.5s ease-in-out;
  -moz-animation: fadeIn 2.5s ease-in-out;
  -o-animation: fadeIn 2.5s ease-in-out;
  animation: fadeIn 2.5s ease-in-out;
}

@media (max-width: 800px) {
  .login-container {
    flex-direction: column;
  }
  .login-container .logo {
    display: none;
  }
}
