@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-Black.ttf') format('truetype'),
    url('../fonts/woff/Roboto-Black.woff') format('woff'),
    url('../fonts/woff2/Roboto-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-BlackItalic.ttf') format('truetype'),
    url('../fonts/woff/Roboto-BlackItalic.woff') format('woff'),
    url('../fonts/woff2/Roboto-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-Bold.ttf') format('truetype'),
    url('../fonts/woff/Roboto-Bold.woff') format('woff'),
    url('../fonts/woff2/Roboto-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-BoldItalic.ttf') format('truetype'),
    url('../fonts/woff/Roboto-BoldItalic.woff') format('woff'),
    url('../fonts/woff2/Roboto-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-Italic.ttf') format('truetype'),
    url('../fonts/woff/Roboto-Italic.woff') format('woff'),
    url('../fonts/woff2/Roboto-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-Light.ttf') format('truetype'),
    url('../fonts/woff/Roboto-Light.woff') format('woff'),
    url('../fonts/woff2/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-LightItalic.ttf') format('truetype'),
    url('../fonts/woff/Roboto-LightItalic.woff') format('woff'),
    url('../fonts/woff2/Roboto-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-Medium.ttf') format('truetype'),
    url('../fonts/woff/Roboto-Medium.woff') format('woff'),
    url('../fonts/woff2/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-MediumItalic.ttf') format('truetype'),
    url('../fonts/woff/Roboto-MediumItalic.woff') format('woff'),
    url('../fonts/woff2/Roboto-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/woff/Roboto-Regular.woff') format('woff'),
    url('../fonts/woff2/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-Thin.ttf') format('truetype'),
    url('../fonts/woff/Roboto-Thin.woff') format('woff'),
    url('../fonts/woff2/Roboto-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/ttf/Roboto-ThinItalic.ttf') format('truetype'),
    url('../fonts/woff/Roboto-ThinItalic.woff') format('woff'),
    url('../fonts/woff2/Roboto-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}