@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-Bold.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-Bold.woff') format('woff'),
         url('../fonts/woff2/OpenSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-BoldItalic.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-BoldItalic.woff') format('woff'),
         url('../fonts/woff2/OpenSans-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-ExtraBold.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-ExtraBold.woff') format('woff'),
         url('../fonts/woff2/OpenSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-ExtraBoldItalic.woff') format('woff'),
         url('../fonts/woff2/OpenSans-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-Italic.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-Italic.woff') format('woff'),
         url('../fonts/woff2/OpenSans-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-Light.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-Light.woff') format('woff'),
         url('../fonts/woff2/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-LightItalic.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-LightItalic.woff') format('woff'),
         url('../fonts/woff2/OpenSans-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-Medium.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-Medium.woff') format('woff'),
         url('../fonts/woff2/OpenSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-MediumItalic.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-MediumItalic.woff') format('woff'),
         url('../fonts/woff2/OpenSans-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-Regular.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-Regular.woff') format('woff'),
         url('../fonts/woff2/OpenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-SemiBold.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-SemiBold.woff') format('woff'),
         url('../fonts/woff2/OpenSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/ttf/OpenSans-SemiBoldItalic.ttf') format('truetype'),
         url('../fonts/woff/OpenSans-SemiBoldItalic.woff') format('woff'),
         url('../fonts/woff2/OpenSans-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
